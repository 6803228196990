.cfg-toolbar {
    position: fixed;
    height: $cfgToolbarHeight;
    bottom: 0;
    left: 0;
    right: 0;

    > * {
        padding: 10px 10px 10px 25px;
        margin-bottom: $footer-height;
    }

    .cfg-toolbar__tools {
        background: $cfg-toolbar-tools-bg;
        min-width: 0; // See https://stackoverflow.com/a/36247448/2564990
        font-size: 13px;
        .label {
            font-size: 16px;
        }
    }

    .cfg-toolbar__nav {
        background: $cfg-toolbar-nav-bg;
        color: $white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-right: 25px;

        .toolbar-nav {
            margin-top: $spacer;
        }

        button {
            padding: 0;
            span {
                display: inline-block;
                border: 1px solid $white;
                border-radius: 50px;
                line-height: 21px;
                height: 24px;
                width: 40px;
                img {
                    height: 17px;
                }
            }
        }
    }

    .vr {
        width: 2px;
        border-radius: 10px;
        margin: 0 $spacer;
        background: $cfg-toolbar-separator-color;
    }

    /* Scrollbar */
    ::-webkit-scrollbar {
        width: 10px;
        height: 10px;
    }
    ::-webkit-scrollbar-track {
        background: #CCC;
        -webkit-border-radius: 10px;
        border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb {
        -webkit-border-radius: 10px;
        border-radius: 10px;
        background: $col4;
    }
    ::-webkit-scrollbar-thumb:window-inactive {
        background: $col4; 
    }
}

.cfg-toolbar__tabs {
    text-align: center;
    position: fixed;
    bottom: $cfgToolbarHeight;
    left: 0;
    right: 0;

    .stage-link {
        font-weight: bold;
        padding: map-get($spacers, 2) map-get($spacers, 5);
        background: lighten($cfg-toolbar-tools-bg, 3%);
        color: lighten($body-color, 40%);
        border-radius: 0;

        &:first-child {
            border-top-left-radius: $border-radius;
        }
        &:last-child {
            border-top-right-radius: $border-radius;
        }

        &.active {
            background: $cfg-toolbar-tools-bg;
            color: $body-color;
        }
        &:hover, &:focus {
            text-decoration: none;
        }
    }
}

.palette-label {
    position: absolute;
    top: 0;
    right: 0;
    font-weight: bold;
}

.palette-list {
    overflow-y: auto;
    white-space: nowrap;
    padding: map-get($spacers, 3) 0;

    & > * {
        padding: 0;
        margin-right: map-get($spacers, 3);

        &:last-child {
            margin-right: 0;
        }
    }

    &.picker {
        .btn {
            display: inline-block;
            color: $body-color;
            margin-right: 0;
            width: 100px;
            text-align: center;

            img {
                display: inline-block;
                width: 50px;
                height: 50px;
                transition: $transition-base;
            }
            span {
                display: block;
                margin-top: 10px;
                white-space: normal;
                line-height: 18px;
            }

            &:hover {
                text-decoration: none;
            }

            &.active {
                font-weight: bold;
                img {
                    transform: scale(1.25);
                }
                span {
                    background: $red;
                    color: $white;
                    border-radius: 5px;
                }
            }
        }
    }
}

.file-tool-button {
    position: relative;
    text-align: center;
    width: 140px;
    height: 100%;
    cursor: pointer;
    display: inline-block;
    vertical-align: top;

    .image {
        display: inline-block;
        border: $cfg-toolbar-button-border;
        border-radius: 25px;
        width: 80px;
        line-height: 40px;
        // margin-bottom: $spacer;
    }
    img {
        height: 30px;
    }
    input {
        opacity: 0;
        z-index: 3;
        width: 100%;
    }
    label {
        position: absolute;
        top: 0; left: 0; bottom: 0; right: 0;
        margin: 0;
        z-index: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

.cfg-toolbar-btn {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    text-align: center;
    width: 140px;
    height: 100%;
    color: $body-color;
    cursor: pointer;

    .image {
        display: inline-block;
        border: $cfg-toolbar-button-border;
        border-radius: 25px;
        width: 80px;
        line-height: 40px;
        //margin-bottom: $spacer;
        img {
            height: 30px;
        }
    }
}

.cart-toolbar {
    position: fixed;
    height: $cfgToolbarHeight;
    bottom: 0;
    left: 0;
    right: 0;
    
    > * {
        padding: map-get($spacers, 5);
    }

    .cart-toolbar__tools {
        background: $cfg-toolbar-tools-bg;
        min-width: 0; // See https://stackoverflow.com/a/36247448/2564990
    }
}
