.lang-switcher {
    border-right: 1px solid white;
    width: 90px;
    min-width: 0 !important;
    .lang {
        text-indent: -9999px;
        display: inline-block;
        border-radius: 9px;
        height: 18px;
        width: 18px;
        margin-top: 2px;
        background-position: center;
        background-size: cover;
        //border: 2px solid $red;
        box-shadow: 0px 0px 2px 1px rgba(0,0,0,0.2);

        &.active {
            border: 2px solid white;
            border-radius: 11px;
            height: 22px;
            width: 22px;
            margin-top: 0;
        }

        &.ita {
            background-image: url('/AVE-ita-100x100.png.webp');
        }
        &.en {
            background-image: url('/AVE-en-100x100.png.webp');
        }
    }
}
