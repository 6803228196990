.stage-nav-bar {
    display: flex;
    justify-content: center;
    margin-top: $header-height;
    padding-top: 20px;
    background-color: $col5;

    .stage-nav-bar__container {
        display: flex;
        position: relative;
    }

    .stage-link {
        color: $stage-link-color;
        text-decoration: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 160px;

        &.active {
            color: $gray-800;
            img {
                opacity: .67;
            }
            span {
                opacity: 1;
            }
        }

        img {
            height: 32px;
            opacity: .33;
            transition: $transition-base;
            margin-bottom: $spacer;
        }

        span {
            font-size: $font-size-sm;
            opacity: .33;
            text-transform: uppercase;
        }
    }

    .stage-nav-bar__progressbar {
        position: absolute;
        background: $stage-progress-bg;
        top: 46px;
        height: 3px;
        left: 60px;
        right: 60px;
        border-radius: 4px;
        & > * {
            position: absolute; left: 0; bottom: 0; top: 0;
            background: $stage-progress-fg;
            border-radius: 10px;
            transition: $transition-base;
        }
    }

    .stage-nav-bar__plate {
        width: 450px;
        padding-left: 50px;
        margin-right: auto;
        text-transform: uppercase;
        h3 {
            font-size: $font-size-base;
            margin-bottom: 0;
            font-weight: bold;
        }
        .model {
            font-size: $h1-font-size;
            font-weight: bold;
            line-height: 1;
            margin-top: map-get($spacers, 2);
        }
    }

    .stage-nav-bar__details {
        width: 450px;
        padding-right: 50px;
        margin-left: auto;
        h3 {
            font-size: $font-size-base;
            margin-bottom: 0;
            text-transform: uppercase;
            font-weight: bold;
        }
        .items {
            margin-bottom: $spacer;
        }
        .item {
            color: $col3;
            &.active {
                font-weight: bold;
                color: $body-color;
            }
        }
        .sep {
            margin: 0 map-get($spacers, 1);
        }
    }
}

.cart-code-bar {
    @keyframes slide-down {
        0% {
            opacity: 0;
            transform: translateY(-50px);
        }
        100% {
            opacity: 1;
            transform: translateY(0);
        }
    }
    padding: 5px;
    background: $cart-code-bar-color;
    position: fixed;
    top: $header-height;
    width: 100%;
    z-index: 1;
    animation: slide-down 0.4s ease;
    > form {
        position: relative;
        > span {
            font-style: italic;
            font-size: 0.7rem;
            padding-right: 10px;
        }
        > input {
            background: white;
            color: $cart-code-bar-color;
            padding: map-get($spacers, 2) $spacer;
            border-radius: 20px;
            border: 0;
            font-style: italic;
            font-size: 0.8rem;
        }
        > button {
            background: $col1;
            color: white;
            font-size: 0.7rem;
            padding: map-get($spacers, 2) $spacer;
            border-radius: 0px 20px 20px 0;
            border: 0;
            position: absolute;
            top: 0;
            right: 0;
            bottom:0;
        }
    }
}
