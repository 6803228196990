.cart-table {
    text-align: center;

    .btn > img {
        width: 40px;
    }

    thead > tr > th {
        border-top: none;
        font-weight: 300;
        color: $cart-table-header-color;
    }

    tr > td {
        border-top: 2px solid $cart-table-header-color;
        vertical-align: middle;
    }

    tfoot > tr > td {
        border-bottom: 2px solid $cart-table-header-color;
        font-weight: 300;
        background: $col2;
        font-weight: bold;
        padding: 50px;
    }

    .cart_quantity-input {
        min-width: 150px;
        
        .btn {
            padding: 0;
            width: 26px;
            height: 26px;
            line-height: 13px;
            border: 1px solid $body-color;
            color: $body-color;
            font-size: 32px;
        }

        input {
            -moz-appearance: textfield;
            &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
            margin: 0 map-get($spacers, 3);
            border: 0;
            outline: 0;
            background: $gray-300;
            text-align: center;
            width: 64px;
            height: 48px;
            border-radius: 4px;
            font-weight: bold;
            
        }
    }

    .cart-item-action {
        width: 300px;
        font-size: 15px;
    }
}