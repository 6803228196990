.jack-preview {
    display: inline-block;
    position: relative;
    background: $white;
    border: $jack-border;

    .jack-preview__placeholder {
        display: inline-block;
        position: absolute;
        background: $jack-background;
        border: $jack-border;
    }
}

.window-editor {
    &::after {
        content: '';
        position: absolute;
        top: 0; left: 0; bottom: 0; right: 0;
        border: $cfg-window-border;
        background: $cfg-window-background;
        pointer-events: none;
    }

    .window-editor__jacks-container {
        position: absolute;
        top: 0; left: 0; bottom: 0; right: 0;
    }

    .window-editor__cells-container {
        display: flex;
        height: 100%;
        position: relative;
        overflow: hidden;
    }

    .window-editor__jack-wrapper {
        position: absolute;
        top: 0; bottom: 0;
        opacity: .8;

        .delete-button {
            position: absolute;
            top: -35px;
            left: 50%;
            background: $cfg-handle-background;
            border: $cfg-handle-border;
            padding: 0;
            text-align: center;
            width: 30px;
            height: 30px;
            margin-left: -15px;
            img {
                width: 100%;
                max-width: 30px;
                max-height: 30px;
            }
        }
    }

    .window-cell {
        flex-grow: 1;
        position: relative;

        &::before {
            content: '';
            position: absolute;
            top: 0; left: 0; right: 0; bottom: 0;
            border-right: $cfg-window-cell-border;
        }

        & > * {
            position: absolute;
            top: 0; left: 0; bottom: 0;
        }

        .window-cell__preview {
            &.ok {
                background: $cfg-cell-hover-ok;
            }
            &.ko {
                background: $cfg-cell-hover-ko;
            }
        }
    }
}
