.cfg__canvas-container {
    margin: map-get($spacers, 4) 0;
    position: relative;

    &.cfg__canvas-container__dark {
        .cfg__droppable-area__image-type-laser {
            filter: invert(100%);
        }    
    }    
}

.cfg__canvas {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}

.cfg__droppable-area {
    position: relative;
    overflow: hidden;

    &::before {
        content: '';
        position: absolute;
        top: 0; left: 0; right: 0; bottom: 0;
        border: $cfg-droppable-area-border;
    }
}

.cfg__draggable-area {
    position: absolute;

    .button {
        background: $cfg-handle-background;
        border: $cfg-handle-border;
        padding: 0;
        text-align: center;
        width: 30px;
        height: 30px;
        margin-right: 2px;
        img {
            width: 100%;
            max-width: 30px;
            max-height: 30px;
        }
    }

    .handle {
        position: absolute;
        background: $cfg-handle-background;
        border: $cfg-handle-border;
        padding: 0;
        text-align: center;
        width: $cfg-handle-half-size * 2;
        height: $cfg-handle-half-size * 2;
    }

    .cfg__draggable-area_toolbar {
        white-space: nowrap;
        position: absolute;
        left: calc(50% - 63px);
        top: -$cfg-selected-border-width - 1 - 35;
    }

    .cfg__draggable-area_resize-handle {
        touch-action: none;
        &.handle-se {
            cursor: nwse-resize;
            bottom: -$cfg-handle-half-size;
            right: -$cfg-handle-half-size;
        }
        &.handle-ne {
            cursor: nesw-resize;
            top: -$cfg-handle-half-size;
            right: -$cfg-handle-half-size;
        }
        &.handle-sw {
            cursor: nesw-resize;
            bottom: -$cfg-handle-half-size;
            left: -$cfg-handle-half-size;
        }
        &.handle-nw {
            cursor: nwse-resize;
            top: -$cfg-handle-half-size;
            left: -$cfg-handle-half-size;
        }
    }

    .cfg__draggable-area_selectable-area {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        &.selected {
            border: $cfg-selected-border;
        }
    }
}

.cfg__nav-button {
    position: absolute;
    border: 1px solid black;
    z-index: 1;
    border-radius: 0;
    background: white;
    left: 20px;
    top: 50%;
    margin-top: -19px;

    &.right {
        right: 20px;
        left: auto;
    }

    &:hover {
        background: $red;
        color: white;
    }
}
