$breadcrumbs-background: $col2;
$border-color: $white;

.header {
    &.navbar {
        padding: 0;
        text-transform: uppercase;

        .navbar-brand {
            margin: 0;
            // width: 15vw;
            min-width: 100px;
            text-align: center;
        }

        .navbar-nav {
            // width: 10vw;
            min-width: 200px;
            justify-content: center;
        }

        .header__breadcrumbs {
            @extend .list-unstyled;
            font-size: 0.8rem;
            margin: 0;
            flex: 1;
            height: $header-height;
            display: flex;
            border-left: 1px solid $border-color;
            border-right: 1px solid $border-color;
            background: $breadcrumbs-background;
            > li {
                position: relative;
                flex-grow: 1;
                &:before {
                    content: '';
                    border-left: $header-height / 2 solid $border-color;
                    border-top: $header-height / 2 solid transparent;
                    border-bottom: $header-height / 2 solid transparent;
                    position: absolute;
                    right: -$header-height / 2 - 3;
                    top: 0;
                    bottom: 0;
                    z-index: 2;
                }
                &:after {
                    content: '';
                    border-left: $header-height / 2 solid $breadcrumbs-background;
                    border-top: $header-height / 2 solid transparent;
                    border-bottom: $header-height / 2 solid transparent;
                    position: absolute;
                    right: -$header-height / 2;
                    top: 0;
                    bottom: 0;
                    z-index: 3;
                }
                &:last-child:before, &:last-child:after {
                    content: none;
                }
                a, span {
                    text-decoration: none;
                    color: $white;
                    display: block;
                    padding: ($header-height - 20px) / 2 0 ($header-height - 24px) / 2 35px;
                }

                &.active {
                    background: $primary;
                    &:after {
                        border-left-color: $primary;
                    }
                }

                &.current {
                    text-decoration: underline;
                    text-decoration-color: white;
                    font-weight: bold;
                }
            }
        }
    }
}
