.catalog-container {
    padding-left: $sidebar-width;
    padding-top: $header-height;
    padding-right: 0;
    .filters {
        width: $sidebar-width;
        position: fixed;
        top: $header-height;
        left: 0;
        bottom: $footer-height;
        overflow-y: auto;

        background: $col2;
        & > div {
            padding: $spacer;
        }

        h2 {
            margin-top: $spacer;
            font-size: $h5-font-size;
            font-weight: bold;
            text-transform: uppercase;
        }

        label {
            text-transform: capitalize;
            &.disabled {
                color: $col3;
            }
        }

        .filter-block {
            margin-bottom: map-get($spacers, 5);
            .disabled {
                input {
                    display: none;
                }
                &:before {
                    content:"•";
                }
                &.checked {
                    color: black;
                    font-weight: bold;
                }
            }
        }
    }
}

.catalog-items {
    margin-top: $spacer;

    .catalog-item {
        margin-bottom: map-get($spacers, 4);
        color: $body-color;
        text-decoration: none;
        text-align: center;

        & > div {
            margin-left: $spacer;
            margin-right: $spacer;
        }

        h3 {
            font-size: $h4-font-size;
            font-weight: bold;
            margin-bottom: 10px;
            text-transform: uppercase;
            font-size: 1rem;
        }

        .img-wrapper {
            height: 225px;
            margin-bottom: map-get($spacers, 2);
            display: flex;
            flex-direction: column;
            justify-content: center;
            background: transparent none center no-repeat;
            background-size: contain;
        }
        
        .details {
            font-size: $font-size-sm;
            font-weight: $font-light;
        }

        .go-btn-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
        .go-btn {
            border: $dark-button-border;
            border-radius: 48px;
            width: 48px;
            padding: 0 map-get($spacers, 2);
        }
    }
}
