@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,700,800&display=swap');

$red: #e71a0f;
$gray-800: #333333;
$primary: $red;
$body-color: $gray-800;
$font-family-sans-serif: 'Open Sans', sans-serif;
$cart-code-bar-color: #dddddd;

$col1: #bbb1a8;
$col2: #efefef;
$col3: #999999;
$col4: #777777;
$col5: #f5f5f5;
$col6: #d5d5d5;

@import '~bootstrap/scss/bootstrap.scss';

$stage-link-color: $col3;
$stage-progress-bg: $col2;
$stage-progress-fg: $col1;
$cfg-toolbar-tools-bg: $col2;
$cfg-toolbar-nav-bg: $col1;
$cfg-toolbar-button-border: 1px solid $gray-800;
$cfg-toolbar-separator-color: $col4;
$jack-border: 1px solid $col4;
$jack-background: $col1;
$cfg-window-border: 1px solid $col4;
$cfg-window-cell-border: 1px dashed $col4;
$cfg-window-background: rgba($white, .3);
$cfg-cell-hover-ok: rgba($col1, .5);
$cfg-cell-hover-ko: rgba($red, .5);
$cfg-droppable-area-border: 1px solid $col4;
$cfg-selected-border-width: 2px;
$cfg-selected-border: $cfg-selected-border-width dashed $col1;
$cfg-handle-background: $white;
$cfg-handle-border: 1px solid $body-color;
$cfg-handle-half-size: 4px;
$cart-table-header-color: $col1;
$font-light: 300;
$dark-button-border: 1px solid $gray-800;
$clear-button-border: 1px solid $white;
$header-height: 45px;
$footer-height: 20px;
$sidebar-width: 250px;
$cfgToolbarHeight: 170px;
$content-fill-height: calc(100vh - #{$header-height + $footer-height});

@import 'layout';
@import 'buttons';
@import 'forms';
@import 'header';
@import 'title';
@import 'nav';
@import 'toolbar';
@import 'cart';
@import 'configurator';
@import 'jacks';
@import 'catalog';
@import 'intro';
@import 'materials';
@import 'language';

::selection {
    color: $white;
    background: $red;
}

hr {
    border-top-width: 2px;
}

@media all and (max-width: 1280px) {
    body {
        font-size: 0.9rem;
    }
    .container {
        max-width: none;
    }
    .btn-ave {
        min-width: 100px;
    }
    .header.navbar .header__breadcrumbs {
        font-size: 0.7rem;
    }
    h1, .h1 {
        font-size: 2rem;
    }
    .stage-nav-bar .stage-nav-bar__details {
        h3, .items {
            font-size: 0.8rem;
        }
        .item {
            display: none;
            &.active {
                display: inline;
            }
        }
        .sep {
            display: none;
        }
    }
    .stage-nav-bar .stage-nav-bar__plate {
        h3 {
            display: none;
        }
        .model {
            font-size: 1.5rem;
        }
    }
    .stage-nav-bar .stage-link {
        img {
            height: 24px;
            margin-bottom: 0;
        }
        span {
            font-size: 11px;;
        }
    }
    #root > footer {
        font-size: 0.5rem;
    }
}

@media all and (max-height: 728px) {
    body {
        font-size: 0.7rem;
    }
    .cfg-toolbar__tabs {
        text-align: left;
        .stage-link {
            padding: 0 10px;
            font-size: 0.9rem,
        }
    }
    .cfg-toolbar .cfg-toolbar__tools {
        .label {
            font-size: 12px;
        }
    }
}