#root {
    overflow: hidden;

    > nav {
        position: fixed;
        top:0;
        left:0;
        right:0;
        height: $header-height;
        z-index: 2;
        > .navbar-nav > .nav-item > .nav-link {
            color: white;
            cursor: pointer;
            font-weight: bold;
            font-size: 0.8rem;
            &:hover {
                text-decoration: underline;
            }
            img {
                height: 20px;
            }
        }
    }
    > .container, >.container-fluid {
        padding-top: $header-height;
        padding-bottom: $footer-height;
        z-index: 1;

        h1 {
            font-weight: 800;
        }
    }
    > footer {
        position: fixed;
        bottom:0;
        left:0;
        right:0;
        height: $footer-height;
        z-index: 3;
        background-color: $col6;
        font-size: 0.6rem;
        > .navbar-nav > .nav-item > .nav-link {
            color: $body-color;
            &:hover {
                text-decoration: underline;
            }
        }
    }
}

