.btn-ave {
    position: relative;
    margin: 0 map-get($spacers, 3);
    padding: map-get($spacers, 1) map-get($spacers, 5);
    background: transparent;
    border: 1px solid;
    font-size: $font-size-sm;
    font-weight: bold;
    border-radius: 50px;
    line-height: 21px;
    height: 31px;
    min-width: 250px;
    img {
        position: absolute;
        left: 10px;
        top: 5px;
        height: 17px;
    }

    &.btn-secondary {
        border-color: $body-color;
        color: $body-color;
        &:hover {
            color: $body-color;
        }
    }

    &.btn-primary {
        border-color: $body-color;
        background-color: $body-color;
        color: white;
        &:hover {
            color: $body-color;
        }
    }

    &:hover {
        background: transparent;
    }
}

.btn:focus {
    box-shadow: none;
}
