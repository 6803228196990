.material-selector {
    height: $content-fill-height;

    > * {
        padding: map-get($spacers, 5) 0;
    }

    a {
        overflow: hidden;
        text-decoration: none;
        color: $white;
        

        img.material-image {
            height: 80%;
            position: absolute;
            top: 50px;
            right: 30%;
            display: block;
            padding-bottom: map-get($spacers, 4);
            transition: all .3s ease;
        }

        &:hover > img.material-image {
            right: 10%;
        }
    }

    .label {
        position: absolute;
        bottom: 30px;
        padding: 0 map-get($spacers, 3);
        font-weight: $font-light;
        > span {
            display: block; 
        }
        .name {
            display: block;
            font-weight: bold;
            font-size: 1.5rem;
            line-height: 1.2rem;
        }
    }

    .go-btn-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        direction: rtl;
    }
    .go-btn {
        border: $clear-button-border;
        border-radius: 40px;
        width: 40px;
        padding: 0 map-get($spacers, 1);
    }
}
