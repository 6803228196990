.intro-container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #000006 url('/preview-home-it.jpg') center;
    background-repeat: no-repeat;
    background-size: contain;
    &.en {
        background-image: url('/preview-home-en.jpg');
    }
}
