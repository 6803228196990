.ave-form {
    input[type=text], input[type=email], textarea {
        background: $gray-300;
        padding: map-get($spacers, 4) $spacer;
        border-radius: 20px;
        border: 0;
    }
    textarea {
        min-height: 20rem;
        resize: vertical;
        padding: $spacer;
        &:focus {
            background: $gray-300;
        }
    }
}
